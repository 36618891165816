html {
  height: 100%;
}

body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
}

@media print {
  html {
    width: auto;
    height: auto;
    overflow: visible;
  }

  .no-print {
    display: none !important;
  }
}
